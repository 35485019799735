// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-essay-page-js": () => import("./../src/templates/essay-page.js" /* webpackChunkName: "component---src-templates-essay-page-js" */),
  "component---src-templates-essays-page-js": () => import("./../src/templates/essays-page.js" /* webpackChunkName: "component---src-templates-essays-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-info-page-js": () => import("./../src/templates/info-page.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-project-page-js": () => import("./../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-site-page-js": () => import("./../src/templates/site-page.js" /* webpackChunkName: "component---src-templates-site-page-js" */),
  "component---src-templates-work-page-js": () => import("./../src/templates/work-page.js" /* webpackChunkName: "component---src-templates-work-page-js" */)
}

