/* eslint-disable react/prop-types */

import React, { useContext } from "react";
import { Link } from "gatsby";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";

const Header = () => {
  const { pathname } = useContext(AppContext);
  const { device } = useContext(DocumentContext);

  const hidden = pathname?.includes(`/sites/`);

  return (
    <header
      className={`header ${
        hidden ? `hidden` : ``
      } transition-transform w-full fixed xs:absolute top-0 right-0 left-0 z-30 pt-4 pb-2`}
    >
      <nav className="grid">
        <Link
          to="/"
          className="grid-end-4 md:grid-end-5 xs:grid-end-12 flex items-center justify-center text-center xs:text-left text-black"
        >
          <h2
            className={`f2 ${device === `mobile` ? `` : `whitespace-no-wrap`}`}
          >
            Drew Pettifer is an artist based in{` `}
            <br className={`${device === `mobile` ? `hidden` : ``}`} />
            Naarm/Melbourne, Australia
          </h2>
        </Link>

        <div className="grid-end-4 md:grid-end-3 xs:grid-end-12 grid-start-9 xs:grid-start-1 xs:flex xs:justify-start xs:-mt-1 text-black">
          <div className="w-full xs:w-auto relative flex items-center justify-center xs:items-start xs:justify-start text-center xs:text-left">
            <Link to="/info" className="header__link block mr-1 f2">
              Info,
            </Link>

            <Link to="/work" className="header__link block mr-1 f2">
              Work,
            </Link>
          </div>

          <div className="w-full xs:w-auto relative flex items-center justify-center xs:items-start xs:justify-start text-center xs:text-left">
            <Link to="/contact" className="header__link block mr-1 f2">
              Contact,
            </Link>

            <Link to="/essays" className="header__link block mr-1 f2">
              Essays
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
